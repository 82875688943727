import { FormButton } from "@app/components/Form/FormButton"
import { Link } from "@app/components/Link"
import { withSection } from "@app/hoc/Section"
import { useImage } from "@app/hooks/useImage"
import { useRoutes } from "@app/hooks/useRoutes"
import { AspectRatio, Box, Image, SimpleGrid, Heading } from "@chakra-ui/react"
import React, { memo, useMemo } from "react"

import type { Section } from "@root/types/global"

type Props = Section & GatsbyTypes.SanitySectionFeaturedCampaigns

const FeaturedCampaigns: React.FC<Props> = withSection(
  memo(({ items, linkStyle, handleTrackingClick, isHomepage }) => {
    const { getGatsbyImage } = useImage()
    const { urlResolver } = useRoutes()

    const campaigns = useMemo(
      () =>
        items?.map(item => ({
          ...item,
          key: item._key,
          imageDesktop: getGatsbyImage(item.image?.desktop, { width: 2000 }),
          imageMobile: getGatsbyImage(item.image?.mobile?.asset ? item.image?.mobile : item.image?.desktop, { width: 1000 }),
          link: urlResolver(item.link),
        })),
      [items, getGatsbyImage, urlResolver]
    )

    return (
      <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={{ base: 0, sm: 5 }} pt={{ base: 0, lg: 8 }} pb={{ base: 6, lg: 8 }}>
        {campaigns?.map((item, index) => (
          <Box key={item.key} w="100%" position="relative" textAlign={"center"} pt={{ base: 6, lg: 0 }}>
            <Link to={item.link?.url} onClick={handleTrackingClick}>
              {linkStyle === "button" ? (
                <FormButton
                  size="md"
                  variant="secondary"
                  w="full"
                  key={index}
                  as={Link}
                  to={item.link.url}
                  borderColor={"#43423E"}
                  margin={"20px 0"}
                  maxW={300}
                >
                  <Heading as="p" size="mainHeading" m={0} textAlign="center" textTransform={"none"}>
                    {item.title}
                  </Heading>
                </FormButton>
              ) : (
                <Link key={index} to={item.link.url}>
                  <Heading mb={{ base: 2 }} as="p" size="mainHeading" textAlign="center" textTransform={"none"}>
                    {item.title}
                  </Heading>
                </Link>
              )}
              <AspectRatio bg="grey.cloud" ratio={4 / 4}>
                <>
                  {item.imageDesktop && (
                    <Image
                      {...item.imageDesktop}
                      alt={item.imageDesktop?.alt || item.title || ""}
                      display={{
                        base: "none !important",
                        sm: isHomepage ? "none !important" : "flex !important",
                        md: "flex !important",
                      }}
                      maxWidth={{ base: "100%", lg: "100%" }}
                      maxHeight={{ base: "100%", lg: "100%" }}
                      objectPosition="top"
                    />
                  )}
                  {item.imageMobile && (
                    <Image
                      {...item.imageMobile}
                      alt={item.imageMobile?.alt || item.title || ""}
                      display={{
                        base: "flex !important",
                        sm: isHomepage ? "flex !important" : "none !important",
                        md: "none !important",
                      }}
                      maxWidth={{ base: "100%", lg: "100%" }}
                      maxHeight={{ base: "100%", lg: "100%" }}
                      left={{ base: "0% !important", lg: "50% !important" }}
                      objectPosition="top"
                    />
                  )}
                </>
              </AspectRatio>
            </Link>
          </Box>
        ))}
      </SimpleGrid>
    )
  })
)

export default FeaturedCampaigns
